<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">CUReT:<br>
                  </font><span class="style6"><a href="http://www.columbia.edu">Columbia</a>-Utrecht Reflectance and Texture Database</span></div></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center"><router-link to="/repository/CURET"><img border="0" src="https://cave.cs.columbia.edu/old/software/curet/gif/webr2.gif" align="TOP"></router-link></div></td>
          </tr>
          <tr>
            <td valign="baseline"><em>
              </em><div align="left">
                <table width="49%" border="0" align="center" cellpadding="0" cellspacing="10">
                  <tbody><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/about">About the Database</router-link> </td>
                  </tr><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/sample">About the Samples</router-link> </td>
                  </tr><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/btfm">BTF (Texture) Measurements</router-link> </td>
                  </tr><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/brdfm">BRDF Measurements</router-link> </td>
                  </tr><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/brdfp">BRDF Parameters</router-link> </td>
                  </tr><tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/download">Download the database</router-link> </td>
                  </tr>
                  <!-- <tr align="CENTER">
                    <td width="50%"><router-link to="/repository/CURET/doc">Documentation</router-link> </td>
                </tr> -->
                </tbody></table>
                </div>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><font size="-1"><a href="mailto:dana@cs.columbia.edu">Comments?</a></font><span class="style1"><!--webbot
bot="Timestamp" i-checksum="14106" endspan --></span>
                  </p>
                </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>